<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/logo.svg">
        <span class="logo-text secondary-text">SÀNG LỌC</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="max-xl:!hidden toggle-sidebar-folded"
                (click)="toggleSidebarFolded()">
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="xl:!hidden toggle-sidebar-opened"
                (click)="toggleSidebarOpened()">
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="flex flex-col user" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">
          <!-- Charlie Adams -->
          {{ userProfile?.username }}
        </div>
        <div class="h5 email hint-text mt-8">
          <!-- adams.charlie@mail.com -->
          {{ userProfile?.email }}
        </div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" [src]="userProfile?.avatar ? userProfile.avatar : 'assets/images/avatars/profile.jpg'">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
