export const locale = {
    lang: 'en',
    data: {
      PAGINATOR: {
        ITEMS_PER_PAGE: 'Items per page',
        NEXT_PAGE: 'Next page',
        PREVIOUS_PAGE: 'Previous page',
        FIRST_PAGE: 'First page',
        LAST_PAGE: 'Last page',
        RANGE: 'Displayed {{startIndex}} — {{endIndex}} of {{length}}',
        RANGE_NO_ITEMS: 'Displayed 0 of {{length}}',
      }
    }
};
