export const locale = {
  lang: 'vi',
  data: {
    PAGINATOR: {
      ITEMS_PER_PAGE: 'Các mục trên mỗi trang',
      NEXT_PAGE: 'Trang sau',
      PREVIOUS_PAGE: 'Trang trước',
      FIRST_PAGE: 'Trang đầu',
      LAST_PAGE: 'Trang cuối',
      RANGE: 'Hiển thị {{startIndex}} - {{endIndex}} trong số {{length}}',
      RANGE_NO_ITEMS: 'Hiển thị 0 trong số {{length}}',
    }
  }
};
