export const locale = {
    lang: 'vi',
    data: {
      NAV: {
        SYSTEM: 'Hệ thống',
        USERS: {
          TITLE: 'Người sử dụng',
        },
        DISEASES: {
          TITLE: 'Danh mục bệnh',
        },
        HEALTHFACILITIES: {
          TITLE: 'Cơ sở Y tế',
        },
        HEALTHCARESTAFFS: {
          TITLE: 'Nhân viên Y tế',
        },
        BUSINESS: 'Nghiệp vụ SLSS',
        INFOS: {
          TITLE: 'Thông tin ban đầu',
        },
        RESULTS: {
          TITLE: 'Thông tin kết quả'
        },
        WAITINGRESULTS: {
          TITLE: 'Danh sách chờ kết quả'
        },
        IMPORTEDRESULTS: {
          TITLE: 'Danh sách không nguy cơ'
        },
        POSITIVERESULTS: {
          TITLE: 'Danh sách nguy cơ cao'
        },
        BROKENRESULTS: {
          TITLE: 'Danh sách Mẫu hỏng'
        },
        REPORTS: {
          TITLE: 'Báo cáo - Thống kê',
          CHILDREN: 'Theo địa chỉ trẻ',
          UNITS: 'Theo đơn vị nhập liệu',
          HEALTH_FACILITIES: 'Theo cơ sở y tế'
        }
      }
    }
};
