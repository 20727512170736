import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from 'app/services/auth.service';
import { appUrls } from 'app/configs/url-configs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  /**
   * Constructor
   *
   * @param router Router
   */
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (!this.authService.isAuthenticated()) {
        this.router.navigate([appUrls.auth.login], { queryParams: { redirect: state.url } });
        return false;
      }

      return true;
  }

}
