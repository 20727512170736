export const locale = {
  lang: 'en',
  data: {
    NAV: {
      SYSTEM: 'System',
      USERS: {
        TITLE: 'Users',
      },
      DISEASES: {
        TITLE: 'Diseases',
      },
      HEALTHFACILITIES: {
        TITLE: 'Health Facilities',
      },
      HEALTHCARESTAFFS: {
        TITLE: 'Health Facilities',
      },
      BUSINESS: 'Screening Business',
      INFOS: {
        TITLE: 'Original Information',
      },
      RESULTS: {
        TITLE: 'Results',
      },
      WAITINGRESULTS: {
        TITLE: 'Waiting results',
      },
      IMPORTEDRESULTS: {
        TITLE: 'Imported results',
      },
      POSITIVERESULTS: {
        TITLE: 'Positive results',
      },
      BROKENRESULTS: {
        TITLE: 'Broken results',
      },
      REPORTS: {
        TITLE: 'Reports',
        CHILDREN: 'By Children Address',
        UNITS: 'By Input Unit',
        HEALTH_FACILITIES: 'By Health Facilities',
      },
    },
  },
};
