import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EnvConfigs } from 'app/configs/env-configs';

@Injectable({
  providedIn: 'root'
})
export class EnvConfigsService {
  private envConfigs: EnvConfigs;
  private envConfigsNull: EnvConfigs = null;

  constructor(private httpClient: HttpClient) { }

  async loadEnvConfig(): Promise<EnvConfigs> {
    if (!this.envConfigs) {
      const data = await this.httpClient.get<EnvConfigs>('/assets/env.json')
        .toPromise<EnvConfigs>();
      return this.envConfigs = data;
    }

    return Promise.resolve(this.envConfigsNull);
  }

  getConfig(): EnvConfigs {
    if (this.envConfigs) {
      return this.envConfigs;
    }
  }
}
