import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from 'app/services/auth.service';
import { appUrls, apiEndpoints } from 'app/configs/url-configs';

@Injectable({
  providedIn: 'any'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(
      private router: Router,
      private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(apiEndpoints.lookup.byInfo)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken('access')}`,
          "Accept-Language": "vi"
        }
      });
    }

    // next.handle returns an observable  which we can pipe other operators onto.
    // We use pipe and tap in RxJS 6, but this would have been the `do` operator previously
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // if the request is unauthorized, make the user log in again
              this.router.navigateByUrl(appUrls.auth.login);
            }
            if (err.status === 500) {
              // if the request is error from the backend API
              this.router.navigateByUrl(appUrls.errors.server);
            }
          }
        }
      )
    );
  }
}
