import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { EnvConfigsService } from './env-configs.service';
import { Credentials, RefreshToken } from 'app/interfaces/http.body.requests';
import {
  TokenObtainPair,
  UserProfile,
} from 'app/interfaces/http.body.responses';
import { ApiEndpoints, apiEndpoints } from 'app/configs/url-configs';

const tokenJWTHelper = new JwtHelperService();
const endpoints: ApiEndpoints = apiEndpoints;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Constructor
   *
   * @param http HttpClient
   * @param envConfigsService EnvConfigsService
   */
  constructor(
    private http: HttpClient,
    private envConfigsService: EnvConfigsService
  ) {}

  /**
   * Check the user logged in or not.
   *
   * @return  A `boolean` which specify the user logged in or not
   */
  isAuthenticated(): boolean {
    // Get the the access token
    const accessToken = this.getToken('access');

    // If there's no accessToken value, make the user log in
    if (!accessToken) {
      return false;
    }
    // Our indication as to whether the user is authenticated or not
    // is if they have an unexpired token. Return a boolean that compares
    // the current time with the token expiry time.
    return !tokenJWTHelper.isTokenExpired(accessToken);
  }

  /**
   * Save `access` and `refresh` tokens into the Local Storage
   *
   * @param tokens The tokens of type `TokenObtainPair` which contains `access` and `refresh` token.
   */
  saveTokens(tokens: TokenObtainPair): void {
    localStorage.setItem('access', tokens.access);
    localStorage.setItem('refresh', tokens.refresh);
    localStorage.setItem(
      'role',
      tokenJWTHelper.decodeToken(tokens.access).role
    );
  }

  /**
   * Save user profile into the Local Storage
   *
   * @param userProfile The object of type `UserProfile`.
   */
  saveUserInfo(userProfile: UserProfile): void {
    localStorage.setItem('user', JSON.stringify(userProfile));
  }

  /**
   * Get user profile from the Local Storage
   *
   * @return The object of type `UserProfile`.
   */
  getUserInfo(): UserProfile {
    return JSON.parse(localStorage.getItem('user'));
  }

  /**
   * Get user role from the Local Storage
   *
   * @return string.
   */
  getUserRole(): string {
    return localStorage.getItem('role');
  }

  isAdminRole(): boolean {
    const userRole = this.getUserRole();
    return !!userRole && userRole === 'admin';
  }

  isHospitalRole(): boolean {
    const userRole = this.getUserRole();
    return !!userRole && userRole === 'Hospital';
  }

  isNormalRole(): boolean {
    const userRole = this.getUserRole();
    return !!userRole && userRole === 'normal';
  }

  /**
   * Remove all items saved on the Local Storage
   */
  removeInfos(): void {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
  }

  /**
   * Get token from the Local Storage
   *
   * @param type The type of token
   *
   * @return  A string of token
   */
  getToken(type: 'access' | 'refresh'): string {
    return localStorage.getItem(type);
  }

  /**
   * Send HTTP POST Request contain credentials to API /auth/login
   *
   * @param credentials The credentials contains in HTTP body request which send to API
   *
   * @return  An `Observable` of the `HTTPResponse` for the request
   */
  login(credentials: Credentials): Observable<TokenObtainPair> {
    return this.http.post<TokenObtainPair>(
      `${this.envConfigsService.getConfig().backEnd}${endpoints.auth.login}`,
      { ...credentials }
    );
  }

  /**
   * Send HTTP POST Request with nothing in body to API /auth/logout
   *
   * @return  An `Observable` of the `HTTPResponse` for the request
   */
  logout(): Observable<any> {
    const refresh = this.getToken('refresh');
    const refreshTokenBody: RefreshToken = { refresh };

    return this.http.post<any>(
      `${this.envConfigsService.getConfig().backEnd}${endpoints.auth.logout}`,
      refreshTokenBody
    );
  }

  /**
   * Send HTTP GET Request with nothing in body to API /auth/me
   *
   * @return  An `Observable` of the `HTTPResponse` for the request
   */
  getProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(
      `${this.envConfigsService.getConfig().backEnd}${endpoints.auth.me}`
    );
  }
}
