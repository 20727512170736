import { OnDestroy, Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as paginatorEnglish } from './i18n/en';
import { locale as paginatorVietnamese } from './i18n/vi';

@Injectable({
  providedIn: 'any'
})
export class MatPaginatorI18n extends MatPaginatorIntl implements OnDestroy {
    private onDestroy$: Subject<boolean> = new Subject();
    private rangeLabelIntl: string;

    constructor(
      private readonly translate: TranslateService,
      private translateParser: TranslateParser,
      private fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        super();

        this.fuseTranslationLoaderService.loadTranslations(paginatorEnglish, paginatorVietnamese);

        // Use the selected language for translations
        this.translate.use('vi');

        this.translate
            .stream([
                'PAGINATOR.ITEMS_PER_PAGE',
                'PAGINATOR.NEXT_PAGE',
                'PAGINATOR.PREVIOUS_PAGE',
                'PAGINATOR.FIRST_PAGE',
                'PAGINATOR.LAST_PAGE',
                'PAGINATOR.RANGE'
            ])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(translations => {
                this.itemsPerPageLabel = translations['PAGINATOR.ITEMS_PER_PAGE'];
                this.nextPageLabel = translations['PAGINATOR.NEXT_PAGE'];
                this.previousPageLabel = translations['PAGINATOR.PREVIOUS_PAGE'];
                this.firstPageLabel = translations['PAGINATOR.FIRST_PAGE'];
                this.lastPageLabel = translations['PAGINATOR.LAST_PAGE'];
                this.rangeLabelIntl = translations['PAGINATOR.RANGE'];

                // this.getRangeLabel = this.getRangeLabel.bind(this);

                this.changes.next();
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('PAGINATOR.RANGE_NO_ITEMS', { length });
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        // Another way
        // return this.translate.instant('PAGINATOR.RANGE', {
        //     startIndex: startIndex + 1,
        //     endIndex,
        //     length
        // });

        return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex: startIndex + 1, endIndex, length });
    }
}
