import { FuseNavigation } from '@fuse/types';

import { appUrls } from 'app/configs/url-configs';

export const adminNavigation: FuseNavigation = {
  id: 'system',
  title: 'System',
  translate: 'NAV.SYSTEM',
  type: 'group',
  icon: 'apps',
  children: [
    {
      id: 'users',
      title: 'Users',
      translate: 'NAV.USERS.TITLE',
      type: 'item',
      icon: 'account_circle',
      url: appUrls.users,
    },
    {
      id: 'diseases',
      title: 'Diseases',
      translate: 'NAV.DISEASES.TITLE',
      type: 'item',
      icon: 'local_pharmacy',
      url: appUrls.diseases,
    },
    {
      id: 'healthFacilities',
      title: 'Health Facilities',
      translate: 'NAV.HEALTHFACILITIES.TITLE',
      type: 'item',
      icon: 'local_hospital',
      url: appUrls.healthFacilities,
    },
    {
      id: 'healthcareStaffs',
      title: 'Healthcare Staffs',
      translate: 'NAV.HEALTHCARESTAFFS.TITLE',
      type: 'item',
      icon: 'person_pin',
      url: appUrls.healthcareStaffs,
    },
  ],
};

export const normalNavigation: FuseNavigation = {
  id: 'system',
  title: 'System',
  translate: 'NAV.SYSTEM',
  type: 'group',
  icon: 'apps',
  children: [
    {
      id: 'diseases',
      title: 'Diseases',
      translate: 'NAV.DISEASES.TITLE',
      type: 'item',
      icon: 'local_pharmacy',
      url: appUrls.diseases,
    },
    {
      id: 'healthFacilities',
      title: 'Health Facilities',
      translate: 'NAV.HEALTHFACILITIES.TITLE',
      type: 'item',
      icon: 'local_hospital',
      url: appUrls.healthFacilities,
    },
    {
      id: 'healthcareStaffs',
      title: 'Healthcare Staffs',
      translate: 'NAV.HEALTHCARESTAFFS.TITLE',
      type: 'item',
      icon: 'person_pin',
      url: appUrls.healthcareStaffs,
    },
  ],
};

export const hospitalNavigation: FuseNavigation[] = [
  {
    id: 'business',
    title: 'Business',
    translate: 'NAV.BUSINESS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'info',
        title: 'Info',
        translate: 'NAV.INFOS.TITLE',
        type: 'item',
        icon: 'info',
        url: appUrls.info,
      },
      {
        id: 'results',
        title: 'Results',
        translate: 'NAV.RESULTS.TITLE',
        type: 'item',
        icon: 'print',
        url: appUrls.results,
      },
      {
        id: 'WaitingResults',
        title: 'Waiting Results',
        translate: 'NAV.WAITINGRESULTS.TITLE',
        type: 'item',
        icon: 'sort',
        url: appUrls.waitingResults,
      },
      {
        id: 'ImportedResults',
        title: 'Imported Results',
        translate: 'NAV.IMPORTEDRESULTS.TITLE',
        type: 'item',
        icon: 'medical_information',
        url: appUrls.importedResults,
      },
      {
        id: 'positiveResults',
        title: 'Positive Results',
        translate: 'NAV.POSITIVERESULTS.TITLE',
        type: 'item',
        icon: 'report',
        url: appUrls.positiveResults,
      },
      {
        id: 'brokenResults',
        title: 'Broken Results',
        translate: 'NAV.BROKENRESULTS.TITLE',
        type: 'item',
        icon: 'report_problem',
        url: appUrls.brokenResults,
      },
    ],
  },
];

export const navigation: FuseNavigation[] = [
  {
    id: 'system',
    title: 'System',
    translate: 'NAV.SYSTEM',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'users',
        title: 'Users',
        translate: 'NAV.USERS.TITLE',
        type: 'item',
        icon: 'account_circle',
        url: appUrls.users,
      },
      {
        id: 'diseases',
        title: 'Diseases',
        translate: 'NAV.DISEASES.TITLE',
        type: 'item',
        icon: 'local_pharmacy',
        url: appUrls.diseases,
      },
      {
        id: 'healthFacilities',
        title: 'Health Facilities',
        translate: 'NAV.HEALTHFACILITIES.TITLE',
        type: 'item',
        icon: 'local_hospital',
        url: appUrls.healthFacilities,
      },
      {
        id: 'healthcareStaffs',
        title: 'Healthcare Staffs',
        translate: 'NAV.HEALTHCARESTAFFS.TITLE',
        type: 'item',
        icon: 'person_pin',
        url: appUrls.healthcareStaffs,
      },
    ],
  },
  {
    id: 'business',
    title: 'Business',
    translate: 'NAV.BUSINESS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'info',
        title: 'Info',
        translate: 'NAV.INFOS.TITLE',
        type: 'item',
        icon: 'info',
        url: appUrls.info,
      },
      {
        id: 'results',
        title: 'Results',
        translate: 'NAV.RESULTS.TITLE',
        type: 'item',
        icon: 'print',
        url: appUrls.results,
      },
      {
        id: 'WaitingResults',
        title: 'Waiting Results',
        translate: 'NAV.WAITINGRESULTS.TITLE',
        type: 'item',
        icon: 'sort',
        url: appUrls.waitingResults,
      },
      {
        id: 'ImportedResults',
        title: 'Imported Results',
        translate: 'NAV.IMPORTEDRESULTS.TITLE',
        type: 'item',
        icon: 'medical_information',
        url: appUrls.importedResults,
      },
      {
        id: 'positiveResults',
        title: 'Positive Results',
        translate: 'NAV.POSITIVERESULTS.TITLE',
        type: 'item',
        icon: 'report',
        url: appUrls.positiveResults,
      },
      {
        id: 'brokenResults',
        title: 'Broken Results',
        translate: 'NAV.BROKENRESULTS.TITLE',
        type: 'item',
        icon: 'report_problem',
        url: appUrls.brokenResults,
      },
      {
        id: 'reports',
        title: 'Reports',
        translate: 'NAV.REPORTS.TITLE',
        type: 'collapsable',
        icon: 'bar_chart',
        children: [
          {
            id: 'byChildren',
            title: 'By Children',
            translate: 'NAV.REPORTS.CHILDREN',
            type: 'item',
            url: appUrls.statistics.children,
            exactMatch: true,
          },
          {
            id: 'byUnits',
            title: 'By Units',
            translate: 'NAV.REPORTS.UNITS',
            type: 'item',
            url: appUrls.statistics.units,
            exactMatch: true,
          },
          {
            id: 'byHealthFacilities',
            title: 'By Health Facilities',
            translate: 'NAV.REPORTS.HEALTH_FACILITIES',
            type: 'item',
            url: appUrls.statistics.healthFacilities,
            exactMatch: true,
          },
        ],
      },
    ],
  },
];
