<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/logo.svg">
        <span class="logo-text">SÀNG LỌC</span>
    </div>

    <button mat-icon-button class="max-xl:!hidden toggle-sidebar-folded"
            (click)="toggleSidebarFolded()">
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="xl:!hidden toggle-sidebar-opened"
            (click)="toggleSidebarOpened()">
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>
