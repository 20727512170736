<mat-toolbar>

    <div class="flex flex-row justify-center items-center sm:justify-between sm:items-center basis-full">

        <!-- FOOTER CONTENT -->
        <!-- / FOOTER CONTENT -->

    </div>

</mat-toolbar>
