// import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FuseMatchMediaService
{
    activeMediaQuery: string;
    onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private _displayNameMap = new Map([
      [Breakpoints.XSmall, 'XSmall'],
      [Breakpoints.Small, 'Small'],
      [Breakpoints.Medium, 'Medium'],
      [Breakpoints.Large, 'Large'],
      [Breakpoints.XLarge, 'XLarge'],
    ]);


    /**
     * Constructor
     *
     * @param {BreakpointObserver} _breakpointObserver
     */
    constructor(
        private _breakpointObserver: BreakpointObserver
    )
    {
        // Set the defaults
        this.activeMediaQuery = '';

        // Initialize
        this._init();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void
    {
        this._breakpointObserver.observe([
              Breakpoints.XSmall,
              Breakpoints.Small,
              Breakpoints.Medium,
              Breakpoints.Large,
              Breakpoints.XLarge,
            ])
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                // map(state => state.matches)
            )
            .subscribe(result => {
              for (const query of Object.keys(result.breakpoints)) {
                if ( this.activeMediaQuery === query ) {
                  continue;
                }
                if (result.breakpoints[query]) {
                  this.activeMediaQuery = this._displayNameMap.get(query) ?? 'Unknown';
                  this.onMediaChange.next(this.activeMediaQuery);
                }
              }
            });
    }

}
