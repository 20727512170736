import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import {
  appUrls, appAuthPath, appErrorsPath, appUsersPath, appDiseasesPath, appHealthFacilitiesPath,
  appHealthcareStaffsPath, appOriginalInformationPath, appResultsPath, appWaitingResultsPath,
  appPositiveResultsPath, appProfilePath, appBoardsPath, appLookupPath, appBrokenResultsPath, appStatisticsPath, appImportedResultsPath
} from 'app/configs/url-configs';


const routes: Routes = [
  {
    path: appAuthPath,
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: appBoardsPath,
    loadChildren: () => import('./main/boards/boards.module').then(m => m.BoardsModule)
  },
  {
    path: appLookupPath,
    loadChildren: () => import('./main/lookup/lookup.module').then(m => m.LookupModule)
  },
  {
    path: appProfilePath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: appUsersPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
  },
  {
    path: appDiseasesPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/diseases/diseases.module').then(m => m.DiseasesModule)
  },
  {
    path: appHealthFacilitiesPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/health-facilities/health-facilities.module').then(m => m.HealthFacilitiesModule)
  },
  {
    path: appHealthcareStaffsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/healthcare-staffs/healthcare-staffs.module').then(m => m.HealthcareStaffsModule)
  },
  {
    path: appOriginalInformationPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/patients/original-information/original-information.module').then(m => m.OriginalInformationModule)
  },
  {
    path: appResultsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/patients/return-results/results.module').then(m => m.ResultsModule)
  },
  {
    path: appWaitingResultsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/patients/waiting-results/waiting-results.module').then(m => m.WaitingResultsModule)
  },
  {
    path: appImportedResultsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/patients/imported-results/imported-results.module').then(m => m.ImportedResultsModule)
  },
  {
    path: appPositiveResultsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/patients/positive-results/positive-results.module').then(m => m.PositiveResultsModule)
  },
  {
    path: appStatisticsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/statistics/statistics.module').then(m => m.StatisticsModule)
  },
  {
    path: appBrokenResultsPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/patients/broken-results/broken-results.module').then(m => m.BrokenResultsModule)
  },
  {
    path: appErrorsPath,
    loadChildren: () => import('./main/errors/errors.module').then(m => m.ErrorsModule)
  },
  {
    path: '',
    redirectTo: appBoardsPath,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: appUrls.errors.notFound
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
